// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "KoolKoor-module--CarrouselWrapper2--nEkjX";
export var DescriptionWrapper = "KoolKoor-module--DescriptionWrapper--Z0EBv";
export var DownloaderWrapper = "KoolKoor-module--DownloaderWrapper--uzmdj";
export var EventWrapper = "KoolKoor-module--EventWrapper--ryLJp";
export var ImageWrapper = "KoolKoor-module--ImageWrapper---Zcom";
export var ImagesWrapper = "KoolKoor-module--ImagesWrapper--DvEgB";
export var MobileTile = "KoolKoor-module--MobileTile--3adC6";
export var PdpWrapper = "KoolKoor-module--PdpWrapper--yZRmp";
export var PhotosWrapper = "KoolKoor-module--PhotosWrapper--HIr4b";
export var TitleWrapper = "KoolKoor-module--TitleWrapper--qI8lN";
export var Wrapper = "KoolKoor-module--Wrapper--fwci7";